import { useState, useEffect } from "react";
import {
  Button,
} from "react-bootstrap";
import "../components/componentCss/translator.css";
import { IoChevronDown } from "react-icons/io5";



const Translator = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [showTranslator, setShowTranslator] = useState(false);
  // let IsLoaded = false;
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: 'en,vi',
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  function isScriptAdded(src) {
    const script = document.querySelector(`script[src="${src}"]`);
    return !!script;
  }

  const scriptAddedf = () => {
    props.scriptHandlet(true);
  }


  useEffect(() => {

    try {
      var addScript = document.createElement("script");
      // Example usage
      const scriptSrc = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      const scriptAdded = isScriptAdded(scriptSrc);
      // console.log('scriptAdded', props.isScriptAdded, scriptAdded)
      if (props.isScriptAdded == false && scriptAdded == true) {
        var script = document.getElementById("googleTranslateScript");
        if (script) {
          script.parentNode.removeChild(script);;
          // console.log("Google Translate script removed.");
          setTimeout(() => {
            const checkOnce = isScriptAdded(scriptSrc);
            if (!checkOnce) {
              scriptAddedf()
              addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  
              );
              addScript.setAttribute("id", "googleTranslateScript");
              // console.log("addScript======", addScript)
              document.body.appendChild(addScript);
              window.googleTranslateElementInit = googleTranslateElementInit;
           
              // IsLoaded = true;
            }
          }, 1000);
  
  
        }
      }
  
      if (props.isScriptAdded == false && scriptAdded == false) {
        scriptAddedf()
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  
        );
        addScript.setAttribute("id", "googleTranslateScript");
        // console.log("addScript======", addScript)
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      
        // IsLoaded = true;
      }
  
    } catch (error) {
      console.log('errorerror',error)
    }
  
   


  });


  const toggleTranslator = () => {
    setShowTranslator(!showTranslator);
  };
  return (
    <>

      <div className="languaDiv">
        <div id="google_translate_element" className="">
        </div>
        <IoChevronDown />
      </div>
      {/* {showTranslator ? (
      <><div id="google_translate_element" className=""></div></>
    ):
    (
      <Button variant="primary" className="px-4" onClick={toggleTranslator}>
      Language
    </Button>
    )} */}


    </>
  );
};

export default Translator;
